import {
  getLoginArguments,
  getTentativeLoginAccountId,
  LoginFnError,
  useLogin,
} from "apollo/mutations/login";
import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  TRACK_EVENTS,
} from "core/consts";
import { LOCAL_STORAGE_KEYS } from "core/model/localStorage";
import { getErrorMessage, isBackendErrorCode } from "core/model/utils/errors";
import { safeJSONParse } from "core/model/utils/strings";
import {
  QueryProgress,
  RateLimitError,
  RateLimitErrorResponse,
} from "core/types";
import { millisecondsToMinutes } from "date-fns";
import { usePreventUnload } from "dsl/hooks";
import { useClearSearchParams } from "dsl/hooks/useRemoveQueryParam";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import Translations from "translations/types";

export type LoginErrorState =
  | {
      challenge?: string;
      email?: string;
      general?: string;
      password?: string;
    }
  | undefined;

export type LoginView = "login" | "challenge";

const isRateLimitErrorResponse = (
  error: unknown,
): error is RateLimitErrorResponse =>
  (error as RateLimitErrorResponse)?.status === 429 &&
  !!(error as RateLimitErrorResponse).body?.expires_at &&
  !!(error as RateLimitErrorResponse).body?.remaining_time;

function handleRateLimitError(
  remainingTime: RateLimitError["remaining_time"],
  translations: Translations,
): LoginErrorState {
  return {
    general: translations.login.biometricfailedAlert.falsePasswordLimitReached({
      timer: String(millisecondsToMinutes(remainingTime) + 1),
    }),
  };
}

function handleChallengeError(translations: Translations): LoginErrorState {
  const datetimestring =
    window.sessionStorage.getItem(LOCAL_STORAGE_KEYS.CHALLENGE_DATETIME) ?? "";
  return {
    challenge: translations.login.challengeScreen.verificationInvalidTimestamp({
      datetimestring,
    }),
  };
}

function handleSsoErrors(
  translations: Translations,
  errorCode?: number,
): LoginErrorState {
  if (isBackendErrorCode(errorCode, translations)) {
    return {
      general: translations.general.errorCodes[errorCode],
    };
  }

  return { general: translations.general.error };
}

function determineErrorByMessage(
  message: string,
  translations: Translations,
): LoginErrorState {
  if (message.includes("connected with unauthorized IP")) {
    return { general: "Connect to 'ACP Connect' in Perimeter81" };
  }
  if (message.includes("no private key")) {
    return { general: translations.auctionResponse.accessDeniedError };
  }
  if (message === translations.login.toastInactiveProvider) {
    return { general: translations.login.toastInactiveProvider };
  }
  if (message === "cleandata in progress") {
    return { general: "cleandata in progress" };
  }
  if (message.toLowerCase().includes("seald")) {
    return { general: message };
  }
  // Log any error messages that don't match our known cases
  console.error("Unexpected error message:", message);
  return { general: translations.login.wrongEmailPassword };
}

export function useHandleLogin({
  careproviderToLog,
}: {
  careproviderToLog?: number;
} = {}) {
  const [loginView, setLoginView] = useState<LoginView>("login");
  const login = useLogin();
  const translations = useTranslations();
  const { trackEvent } = useTracking();
  const [progressLogin, setProgressLogin] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const [loginErrors, setErrors] = useState<LoginErrorState>();
  const isLogin = loginView === "login";
  const challengeTimestamp =
    window.sessionStorage.getItem(LOCAL_STORAGE_KEYS.CHALLENGE_DATETIME) ?? "";
  const [search] = useSearchParams();
  const errorCode = search.get("errorType");

  useEffect(() => {
    if (errorCode) {
      const error = handleSsoErrors(translations, Number(errorCode));
      setErrors(error);
    }
  }, [errorCode, setErrors, translations]);

  useClearSearchParams(["errorType"]);

  const resetError = useCallback(() => {
    setErrors(undefined);
  }, [setErrors]);

  const handleLogin = useCallback(
    async (
      {
        challenge,
        email,
        password,
        ssoToken,
      }: {
        challenge?: string;
        email?: string;
        password?: string;
        ssoToken?: string;
      },
      setProgress: Dispatch<SetStateAction<QueryProgress>>,
    ) => {
      resetError();
      setProgress(QUERY_PROGRESS_PENDING);
      try {
        const loginArgs = await getLoginArguments({
          username: email as string,
          password: password as string,
          challenge,
          careproviderToLog,
          token: ssoToken,
        });
        await login(loginArgs);
        setProgress(QUERY_PROGRESS_SUCCEED);
      } catch (error) {
        if ((error as LoginFnError).needsChallenge) {
          setLoginView("challenge");
          setProgress(QUERY_PROGRESS_NOT_STARTED);
          return;
        }
        let errors: LoginErrorState;
        const message = getErrorMessage(error);

        const [, maybeRateLimitErrorResponse] = safeJSONParse(message);
        if (isRateLimitErrorResponse(maybeRateLimitErrorResponse)) {
          errors = handleRateLimitError(
            maybeRateLimitErrorResponse.body.remaining_time,
            translations,
          );
        } else if (challenge) {
          errors = handleChallengeError(translations);
        } else {
          errors = determineErrorByMessage(message, translations);
        }

        trackEvent({
          name: TRACK_EVENTS.LOGIN_ATTEMPT_FAILED,
          account_id: getTentativeLoginAccountId(),
          email,
          error_message: message,
          reason: errors,
        });

        setErrors(errors);
        setProgress(QUERY_PROGRESS_FAILED);
      }
    },
    [
      determineErrorByMessage,
      getErrorMessage,
      getLoginArguments,
      getTentativeLoginAccountId,
      handleChallengeError,
      handleRateLimitError,
      handleSsoErrors,
      isRateLimitErrorResponse,
      login,
      setErrors,
      setLoginView,
      trackEvent,
      translations,
    ],
  );

  usePreventUnload(progressLogin);

  return {
    challengeTimestamp,
    handleLogin,
    isLogin,
    loginErrors,
    loginView,
    progressLogin,
    resetError,
    setLoginView,
    setProgressLogin,
  };
}
