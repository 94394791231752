import { ApolloClient } from "@apollo/client";
import { activateSeald } from "core/model/utils/featureFlags";
import {
  Account,
  ApolloCacheData,
  AppType,
  EncryptionContext,
  EnvContext,
  SEALD_FLOW_TYPES,
} from "core/types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTracking } from "react-tracking";
import { useLocale } from "translations";
import { getError } from "../model/utils/errors";
import { UpdateSealdRegistered, importIdentity } from "./authFlows";
import { CreateGroupInContextProps, createSealdGroup } from "./groups";
import { isSealdSDKRegistered } from "./sdk";
import { CreateSessionInContextProps, createSealdSession } from "./sessions";

export type SealdContextType = {
  createSealdGroup: (props: CreateGroupInContextProps) => Promise<void>;
  createSealdSession: (
    props: CreateSessionInContextProps,
  ) => Promise<EncryptionContext | undefined>;
  importSealdIdentity: (
    props: ImportIdentityInContextProps,
  ) => Promise<true | undefined>;
  isActive: boolean;
};

type ImportIdentityInContextProps = {
  account: Account;
  challenge: string | undefined;
  password?: string;
  seald_flow: SEALD_FLOW_TYPES | null;
  seald_force_challenge: boolean | undefined;
  token: string;
  twoManSessionId?: string;
  updateAccount: UpdateSealdRegistered;
};

export const SealdContext = React.createContext<SealdContextType | undefined>(
  undefined,
);

export const useSealdContext = () => {
  const context = useContext(SealdContext);
  if (!context)
    throw new Error(
      "useSealdContext must be used within a SealdContext.Provider",
    );
  return context;
};

function useImportIdentityWhenLogged({
  app,
  importSealdIdentity,
  loggedAccount,
  onLogout,
}: {
  app: AppType;
  importSealdIdentity: SealdContextType["importSealdIdentity"];
  loggedAccount: Account | undefined;
  onLogout: () => void;
}) {
  const [restoring, setRestoring] = useState(false);
  const [active, setActive] = useState(false);

  const restoreSealdIdentity = async (account: Account | undefined) => {
    if (!account?.id) return;

    setRestoring(true);

    try {
      await importSealdIdentity({
        account,
        challenge: undefined,
        token: "",
        seald_flow: null,
        seald_force_challenge: undefined,
        updateAccount: null,
      });

      const isRegistered = await isSealdSDKRegistered();
      setActive(isRegistered);
    } catch (err) {
      const error = getError(err);
      console.error(
        `Seald restore id failed - account id ${account.id}: ${error.message}`,
        error,
      );
      onLogout();
    } finally {
      setRestoring(false);
    }
  };

  useEffect(() => {
    console.log("...");
    async function restore() {
      if (!activateSeald(app)) return;

      try {
        const isRegistered = await isSealdSDKRegistered();
        if (isRegistered) {
          setActive(true);
          return;
        }

        await restoreSealdIdentity(loggedAccount);
      } catch (error) {
        console.error("Seald status check failed", error);
      }
    }

    restore();
  }, [loggedAccount?.id, importSealdIdentity, onLogout]);

  return { restoring, isActive: active };
}

export default function SealdContextProvider({
  apolloClient,
  app,
  children,
  envContext,
  Loading,
  loggedAccount,
  onLogout,
}: {
  Loading: React.ReactNode;
  apolloClient: ApolloClient<ApolloCacheData>;
  app: AppType;
  children: React.ReactNode;
  envContext: EnvContext;
  loggedAccount: Account | undefined;
  onLogout: () => void;
}) {
  const { trackEvent } = useTracking();
  const localeString = useLocale();

  const importIdentityInContext = useCallback(
    ({
      account,
      challenge,
      password,
      seald_flow,
      seald_force_challenge,
      token,
      twoManSessionId,
      updateAccount,
    }: ImportIdentityInContextProps) => {
      return importIdentity({
        account,
        seald_flow,
        seald_force_challenge,
        challenge,
        password,
        token,
        updateAccount,
        twoManSessionId,
        // in context
        envContext,
        trackEvent,
        localeString,
      });
    },
    [envContext],
  );

  const { isActive, restoring } = useImportIdentityWhenLogged({
    loggedAccount,
    importSealdIdentity: importIdentityInContext,
    app,
    onLogout,
  });

  const createGroupInContext = useCallback(
    ({
      createEncryptionContext,
      createSealdAccess,
      entity,
      entityAccounts,
      entityType,
      initiateSealdIdentity,
      loggedAccount,
    }: CreateGroupInContextProps) =>
      createSealdGroup({
        entityType,
        entity,
        entityAccounts,
        initiateSealdIdentity,
        createEncryptionContext,
        createSealdAccess,
        loggedAccount,
        // in context
        envContext,
        trackEvent,
        app,
        apolloClient,
        localeString,
      }),
    [loggedAccount, envContext, app],
  );

  const createSealdSessionInContext = useCallback(
    ({
      checkSealdGroupAccess,
      createEncryptionContext,
      createSealdAccess,
      entity,
      entityType,
      entityUsersDisplayIds,
      loggedGroupDisplayId,
    }: CreateSessionInContextProps) =>
      createSealdSession({
        entityType,
        entity,
        entityUsersDisplayIds,
        createEncryptionContext,
        createSealdAccess,
        checkSealdGroupAccess,
        loggedGroupDisplayId,
        // in context
        loggedAccount,
        envContext,
        app,
        apolloClient,
      }),
    [loggedAccount, envContext, app],
  );

  if (restoring) return Loading;

  return (
    <SealdContext.Provider
      value={{
        importSealdIdentity: importIdentityInContext,
        createSealdGroup: createGroupInContext,
        createSealdSession: createSealdSessionInContext,
        isActive,
      }}
    >
      {children}
    </SealdContext.Provider>
  );
}
